import React from 'react';

function Fidelity() {
  return (
    <div>
      <h1>Fidélité</h1>
    </div>
  );
}

export default Fidelity;