import { useState } from "react";
import { useAuth } from "../AuthProvider";

const SignUp = () => {
  const [input, setInput] = useState({
    lastName: "",
    firstName: "",
    email: "",
    password: "",
    birthDate: "",
    postalCode: "",
    phoneNumber: "",
    role: "CUSTOMER"
  });

  const [confirmPassword, setConfirmPassword] = useState('');

  const auth = useAuth();

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const errorMessageDiv = document.getElementById("errorMessage");

    if (input.lastName !== "" && input.firstName !== "" && input.email !== "" && input.password !== "" && confirmPassword !== "" && input.birthDate !== "" && input.postalCode !== "") {
      if (input.password.length < 8 || !/[A-Z]/.test(input.password) || !/[a-z]/.test(input.password) || !/\d/.test(input.password) || !/[^a-zA-Z0-9]/.test(input.password)){
        errorMessageDiv.textContent = "Le mot de passe doit contenir au minimum 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial";
        return;
      }

      if (input.password !== confirmPassword){
        errorMessageDiv.textContent = "Les mots de passes sont différents";
        return;
      }

      if (new Date(input.birthDate) >= new Date()){
        errorMessageDiv.textContent = "La date de naissance ne peut pas être supérieur à la date d'aujourd'hui";
        return;
      }

      const result = await auth.signUp(input);

      if (!result.success){
        errorMessageDiv.textContent = result.message;
        return;
      }

      return;
    }

    errorMessageDiv.textContent = "Veuillez rempire tous les champs";
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div>
        <div className="mb-3 text-center">
          <h1>Inscription</h1>
        </div>
        <div className="col-md-6 mx-auto">
          <form onSubmit={handleSubmitEvent}>
            <div className="mb-3">
              <label htmlFor="SignUp_LastName" className="form-label">Nom: *</label>
              <input
                className="form-control"
                type="text"
                id="SignUp_LastName"
                name="lastName"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_FirstName" className="form-label">Prénom: *</label>
              <input
                className="form-control"
                type="text"
                id="SignUp_FirstName"
                name="firstName"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_Email" className="form-label">Email: *</label>
              <input
                className="form-control"
                type="email"
                id="SignUp_Email"
                name="email"
                placeholder="example@gmail.com"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_Password" className="form-label">Mot de passe: *</label>
              <input
                className="form-control"
                type="password"
                id="SignUp_Password"
                name="password"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_ConfirmPassword" className="form-label">Confirmer votre mot de  passe: *</label>
              <input
                className="form-control"
                type="password"
                id="SignUp_ConfirmPassword"
                name="confirmPassword"
                onChange={handleInputConfirmPassword}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_BirthDate" className="form-label">Date de naissance: *</label>
              <input
                className="form-control"
                type="date"
                id="SignUp_BirthDate"
                name="birthDate"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_PostalCode" className="form-label">Code postal: *</label>
              <input
                className="form-control"
                type="text"
                id="SignUp_PostalCode"
                name="postalCode"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignUp_PhoneNumber" className="form-label">Numéro de téléphone:</label>
              <input
                className="form-control"
                type="tel"
                id="SignUp_PhoneNumber"
                name="phoneNumber"
                onChange={handleInput}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">Submit</button>
              <div id="errorMessage" className="text-danger"></div>
            </div>
          </form>
        </div>
    </div>
  );
};

export default SignUp;