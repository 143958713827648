import React from 'react';
import '../css/news.css';

function News() {
  return (
    <div className='news'> 
        <form style={{display: 'flex'}}>
            <input 
            type="email" 
            className="form-control email me-1" 
            placeholder="email@example.com" 
            aria-label="Email" 
            aria-describedby="basic-addon1" 
            />
            <button className="btn button" type="submit">
            <span className='text-white'>Souscrire</span> 
            </button>
        </form>
    </div>
  );
}

export default News;