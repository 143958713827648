import AxiosInstance from "./AxiosInstance";

class CraftMenService {
    async getCraftMenList() {
        const { data } = await AxiosInstance.get(`/craftmen`);
        return data;
    }

    async getCraftManDetail(craftManId) {
        const { data } = await AxiosInstance.get(`/craftman/${craftManId}`);
        return data;
    }
}

const instanceCraftMenService = new CraftMenService();
export default instanceCraftMenService;
