import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from "./screens/SignIn";
import Home from "./screens/Home";
import SignUp from "./screens/SignUp.js";
import PersonalCustomerAccount from "./screens/PersonalCustomerAccount.js";
import PersonalProfessionalAccount from "./screens/PersonalProfessionalAccount.js";
// import ActivitiesList from "./screens/ActivitiesList.js";
// import ActivityDetail from "./screens/ActivityDetail.js";
import CraftMenList from "./screens/CraftMenList.js";
import CraftManDetail from "./screens/CraftManDetail.js";
import ContactForm from "./screens/ContactForm.js";
import Blog from "./screens/Blog.js";
import AboutUs from "./screens/AboutUs.js";
import Box from "./screens/Box.js";
import Fidelity from "./screens/Fidelity.js";

import AuthProvider from "./AuthProvider.js";
import { PrivateRouteCustomer, PrivateRouteProfessional } from "./router/route.js";

import Header from "./components/Header.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <div style={{backgroundColor: '#FFFAF0'}}>
      <Router>
        <AuthProvider>

          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            {/* <Route path="/activities" element={<ActivitiesList />} /> */}
            {/* <Route path="/activitydetail/:id" element={<ActivityDetail />} /> */}
            <Route path="/artisans" element={<CraftMenList />} />
            <Route path="/artisandetail/:id" element={<CraftManDetail />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/box" element={<Box />} />
            <Route path="/fidelity" element={<Fidelity />} />
            <Route element={<PrivateRouteCustomer />}>
              <Route path="/personalcustomeraccount" element={<PersonalCustomerAccount />} />
            </Route>
            <Route element={<PrivateRouteProfessional />}>
              <Route path="/personalprofessionalaccount" element={<PersonalProfessionalAccount />} />
            </Route>
          </Routes>

          <Footer />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
