import React from 'react';
import { Link } from 'react-router-dom';
import '../css/header.css';
import logo from '../images/Header_Logo.svg';

function Header() {
    return (
        <header className='pt-3 pb-3'>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand ms-4 p-0">
                        <img src={logo} alt="Logo" className="logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to="/aboutus" className="nav-link fw-bold">À propos</Link>                
                            </li>
                            <li className="nav-item">
                                <Link to="/artisans" className="nav-link fw-bold">Artisans</Link> 
                            </li>
                            <li className="nav-item">
                                <Link to="/fidelity" className="nav-link fw-bold">La fidélité</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/box" className="nav-link fw-bold">Box</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/blog" className="nav-link fw-bold">Blog</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link fw-bold">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link fw-bold color-text">Devenir partenaire</Link>
                            </li>
                        </ul>
                        <div className="ms-auto d-flex">
                            <Link to="/signin">
                                <button className="btn me-2 rounded-pill color-button-blue" type="button">
                                    <i className="fas fa-user text-white"></i> <span className='text-white'>Espace Client</span> 
                                </button>
                            </Link>
                            <Link to="/signin">
                                <button className="btn rounded-pill me-4 color-button-orange" type="button">
                                    <i className="fas fa-tools text-white"></i> <span className='text-white'>Espace artisan</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;