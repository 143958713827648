import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthProvider";

const PrivateRouteCustomer = () => {
  const user = useAuth();
  if (!user.token || user.user?.role !== "CUSTOMER") return <Navigate to="/signin" />;
  return <Outlet />;
};

const PrivateRouteProfessional = () => {
  const user = useAuth();
  if (!user.token || user.user?.role !== "PROFESSIONAL") return <Navigate to="/signin" />;
  return <Outlet />;
};

export { PrivateRouteCustomer, PrivateRouteProfessional };