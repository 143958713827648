import React from 'react';

function AboutUs() {
  return (
    <div>
      <h1>A propos</h1>
    </div>
  );
}

export default AboutUs;