import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/home.css';
import CraftMenService from "../api/CraftMenService";
import { ReactComponent as LogoSvg } from '../images/Home_Logo_Localys.svg';
import { ReactComponent as MainImage } from '../images/Home_Main_Image.svg';
import { ReactComponent as FidelityImage1 } from '../images/Home_Fidelity_1.svg';
import { ReactComponent as FidelityImage2 } from '../images/Home_Fidelity_2.svg';
import { ReactComponent as FidelityImage3 } from '../images/Home_Fidelity_3.svg';
import { ReactComponent as ContactImage1 } from '../images/Home_Contact_1.svg';
import { ReactComponent as ContactImage2 } from '../images/Home_Contact_2.svg';
import ImageBox1 from '../images/Home_Box_Image1.png';
import { ReactComponent as NewsImage1 } from '../images/Home_News_1.svg';
import News from "../components/News.js";

function Home() {
  const [craftMen, setCraftMen] = useState([]);

  async function loadCraftMen(){
    const response = await CraftMenService.getCraftMenList();

    setCraftMen(response);
  }

  useEffect(() => {
    loadCraftMen();
  }, []);

  return (
    <div className='container'  style={{overflow: 'hidden'}}>
      <section className='home mt-5 mb-5'>
        <div className='ribbon ribbon1'></div>
        <div className='ribbon ribbon2'></div>
        <div className='row mb-5'>
          <div className='col-md-7'>
            <LogoSvg className='logo' />
            <span className='title ms-1'>
              , votre nouvelle 
              <br />
              bonne habitude !
            </span>
          </div>
          <div className='col-md-5' style={{overflow: 'hidden'}}>
            <MainImage className='mainImage' />
          </div>
        </div>
        <div className='row d-flex align-items-center justify-content-center stepLocalysParent'>
          <div className='col-md-3 stepLocalys'>
            <span>Découvrir l’artisanat</span>
          </div>
          <div className='col-md-1 text-center'>
            <span>.....</span>
          </div>
          <div className='col-md-2 stepLocalys'>
            <span>Acheter local</span>
          </div>
          <div className='col-md-1 text-center'>
            <span>.....</span>
          </div>
          <div className='col-md-2 stepLocalys'>
            <span>Être récompensé</span>
          </div>
          <div className='col-md-1 text-center'>
            <span>.....</span>
          </div>
          <div className='col-md-2 stepLocalys'>
            <span>Recommencer</span>
          </div>
        </div>
      </section>
      <section className='fidelity mb-5 pt-3'>
        <div className='ribbon'></div>
        <div className='row d-flex align-items-center mb-4'>
          <div className='col-md-6 text-start title'>
            <p>Comment cagnotter ?</p>
          </div>
          <div className='col-md-6 text-end'>
            <Link to="/fidelity" className="link">En savoir plus &gt;</Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 text-center'>
            <div className='row mb-3' style={{display: 'block'}}>
              <FidelityImage1 className='fidelityImage' />
            </div>
            <div className='row'>
              <span className='text-black'>Achetez dans les boutiques de nos </span><span className='text-orange'>artisans partenaires...</span>
            </div>
          </div>
          <div className='col-md-4 text-center'>
            <div className='row mb-3' style={{display: 'block'}}>
              <FidelityImage2 className='fidelityImage' />
            </div>
            <div className='row'>
              <span className='text-black'>Achetez dans les boutiques de nos </span><span className='text-orange'>artisans partenaires...</span>
            </div>
          </div>
          <div className='col-md-4 text-center'>
            <div className='row mb-3' style={{display: 'block'}}>
              <FidelityImage3 className='fidelityImage' />
            </div>
            <div className='row'>
              <span className='text-black'>Achetez dans les boutiques de nos </span><span className='text-orange'>artisans partenaires...</span>
            </div>
          </div>
        </div>
      </section>
      <section className='craftmen mb-5 pt-3'>
        <div className='row d-flex align-items-center mb-4'>
          <div className='col-md-6 text-start title'>
            <p>Rencontrez nos artisans !</p>
          </div>
          <div className='col-md-6 text-end'>
            <Link to="/artisans" className="link">Voir tous nos partenaires &gt;</Link>
          </div>
        </div>
        <div className='row'>
        {craftMen && craftMen.length > 0 ? (
            craftMen.map((craftMan, index) => (
              <Link
                to={`/artisandetail/${craftMan.id}`}
                className="col-md-3 mb-5 text-center"
                key={`craftmen-${index}`}
                style={{ textDecoration: 'none' }}
              >
                <img src={craftMan.image} className="craftManImage mb-3" alt={craftMan.title} style={{ height: '200px', objectFit: 'cover' }} />
                <div className='row'>
                  <span className='text-orange'>{craftMan.title}</span>
                </div>
                <div className='row'>
                  <span className='text-black'>{craftMan.city}</span>
                </div>
              </Link>
            ))
          ) : (
            <div className="col-12">
              <div className="text-center" role="alert">
                <h5>Aucun artisan disponible pour le moment.</h5>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className='contact mb-5 pt-3'>
        <div className='ribbon ribbon1'></div>
        <div className='ribbon ribbon2'></div>
        <div className='row d-flex align-items-center mb-4'>
          <div className='col-md-12 text-start title'>
            <p>Rejoignez l’aventure Localys !</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 text-center ps-5 pe-5'>
            <div className='row mb-3' style={{display: 'block'}}>
              <ContactImage1 className='contactImage' />
            </div>
            <div className='row'>
              <p className='subtitle-black'>
                Vous êtes client ?
              </p>
            </div>
            <div className='row'>
              <p className='text-black'>
                Lorem ipsum dolor sit amet consectetur. Luctus dui scelerisque non morbi nulla suscipit.
              </p>
            </div>
            <div className='row'>
              <Link to="/signup" style={{display: 'grid', textDecoration: 'none'}}>
                <button className="btn button" type="button">
                    <span className='text-white'>Je crée mon compte</span> 
                </button>
              </Link>
            </div>
          </div>
          <div className='col-md-6 text-center ps-5 pe-5'>
            <div className='row mb-3' style={{display: 'block'}}>
              <ContactImage2 className='contactImage' />
            </div>
            <div className='row'>
              <p className='subtitle-black'>
                Vous êtes artisan ou commerçant local ?
              </p>
            </div>
            <div className='row'>
              <p className='text-black'>
                Lorem ipsum dolor sit amet consectetur. Luctus dui scelerisque non morbi nulla suscipit.
              </p>
            </div>
            <div className='row'>
              <Link to="/signup" style={{display: 'grid', textDecoration: 'none'}}>
                <button className="btn button" type="button">
                    <span className='text-white'>Je rejoins l’aventure</span> 
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className='box mb-5 pt-3'>
        <div className='row d-flex align-items-center mb-4'>
          <div className='col-md-6 text-start title'>
            <p>Les box mensuelles</p>
          </div>
          <div className='col-md-6 text-end'>
            <Link to="/box" className="link">Découvrir toutes les boxes  &gt;</Link>
          </div>
        </div>
        <div className='row mb-3'>
          <p className='subtitle-black'>
            Chaque mois, découvrez une sélection de produits de votre région livrés chez vous en vous abonnant à nos box mensuelles Localys.
          </p>
        </div>
        <div className='row'>
          <Link to="/box" className="col-md-4 mb-3" style={{ textDecoration: 'none' }}>
            <div className="card">
              <img src={ImageBox1} className="card-img-top" alt="" />
              <div className="card-body" style={{display: 'grid'}}>
                <p className="title-card">Box apéro</p>
                <p className="subtitle-card">19,99€/mois</p>
                <p className="text-black text-start">Apéoooooo ! Mais avec de bons produits locaux, tous les mois chez vous.</p>
                <button className="btn button" type="button">
                    <span className='text-white'>Je veux !</span> 
                </button>
              </div>
            </div>
          </Link>
          <Link to="/box" className="col-md-4 mb-3" style={{ textDecoration: 'none' }}>
            <div className="card">
              <img src={ImageBox1} className="card-img-top" alt="" />
              <div className="card-body" style={{display: 'grid'}}>
                <p className="title-card">Box apéro</p>
                <p className="subtitle-card">19,99€/mois</p>
                <p className="text-black text-start">Apéoooooo ! Mais avec de bons produits locaux, tous les mois chez vous.</p>
                <button className="btn button" type="button">
                    <span className='text-white'>Je veux !</span> 
                </button>
              </div>
            </div>
          </Link>
          <Link to="/box" className="col-md-4 mb-3" style={{ textDecoration: 'none' }}>
            <div className="card">
              <img src={ImageBox1} className="card-img-top" alt="" />
              <div className="card-body" style={{display: 'grid'}}>
                <p className="title-card">Box apéro</p>
                <p className="subtitle-card">19,99€/mois</p>
                <p className="text-black text-start">Apéoooooo ! Mais avec de bons produits locaux, tous les mois chez vous.</p>
                <button className="btn button" type="button">
                    <span className='text-white'>Je veux !</span> 
                </button>
              </div>
            </div>
          </Link>
        </div>
      </section>
      {/* <section className='map mb-5 pt-3'>
        <div className='row'>

        </div>
      </section> */}
      <section className='news mb-5 ms-2 me-2 pt-3'>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-8 p-5 text-center card-news">
            <NewsImage1 className='newsImage' />
            <div className='row'>
              <p className='title-news'>Connectez-vous à l’artisanal local</p>
            </div>
            <div className='row'>
              <p className='text-black'>Lorem ipsum dolor sit amet consectetur.</p>
            </div>
            <div className='row'>
              <News />
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default Home;
