import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';
import logo from '../images/Logo_Localys.png';
import News from "../components/News.js";

function Footer() {
  return (
    <footer className="footer-container pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card footer-card p-5">
              <div className='row'>
                <p className='footer-card-title'>Rejoignez le programme !</p>
              </div>
              <div className='row'>
                <p className='footer-text'>Artisan ou client, trouvez y votre compte avec Localys.</p>
              </div>
              <Link to="/contact" className='text-center mb-3'>
                <button className="btn footer-button-orange w-100" type="button">
                  <span className='text-white'>Je deviens artisan Localys</span>
                </button>
              </Link>
              <Link to="/contact" className='text-center mb-3'>
                <button className="btn footer-button-blue w-100" type="button">
                  <span className='text-white'>Je me crée un compte fidélité</span>
                </button>
              </Link>
            </div>
          </div>
          <div className='col-md-8 mb-3'>
            <div className='row mb-3 mt-3'>
              <div className="col-md-3">
                <ul className="list-unstyled">
                  <li><Link to="/" className="nav-link footer-text m-1">Accueil</Link></li>
                  <li><Link to="/aboutus" className="nav-link footer-text m-1">À propos</Link></li>
                  <li><Link to="/artisans" className="nav-link footer-text m-1">Les artisans</Link></li>
                  <li><Link to="/fidelity" className="nav-link footer-text m-1">La fidélité</Link></li>
                </ul>
              </div>
              <div className="col-md-5">
                <ul className="list-unstyled">
                  <li><Link to="/blog" className="nav-link footer-text m-1">Blog</Link></li>
                  <li><Link to="/box" className="nav-link footer-text m-1">Box</Link></li>
                  <li><Link to="/contact" className="nav-link footer-text m-1">Contact</Link></li>
                  <li><Link to="/contact" className="nav-link footer-text m-1">Rejoindre Localys</Link></li>
                </ul>
              </div>
              <div className="col-md-4">
                <p className="footer-text m-1">contact@localys.com</p>
                <p className="footer-text m-1">41 rue de l’aérodrome</p>
                <p className="footer-text m-1">74960 Annecy</p>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className='col-md-12'>
                <div className="row text-white">
                  <p className='footer-card-title'>La newsletter Localys</p>
                </div>
                <div className='row'>
                  <div className="col-md-8 pe-1">
                    <News />
                  </div>
                </div>
              </div>           
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className='footer-text'>Mentions légales</p>
              </div>
              <div className="col-md-5 text-end">
                <img src={logo} alt="Logo" className="footer-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;