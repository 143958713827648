import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CraftMenService from "../api/CraftMenService";

function CraftManDetail(props){
  const [craftMan, setCraftMan] = useState(null);
  const params = useParams();

  async function loadCraftManDetail(){
    const craftManId = params.id;

    const response = await CraftMenService.getCraftManDetail(craftManId);

    setCraftMan(response);
  }

  useEffect(() => {
    loadCraftManDetail();
  }, []);

  if(!craftMan){
    return <div className='text-center'><h1>Artisan non trouvé</h1></div>
  }

  return (
    <div>
      <h3 className="text-capitalize">{craftMan.title}</h3>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <img src={craftMan.image} className="card-img-top" alt="" />
        </div>

        <div className="col-md-6 col-sm-12">
          <p>{craftMan.description}</p>
          <p>Adresse : {craftMan.address} {craftMan.postalCode} {craftMan.city}</p>
        </div>
      </div>
    </div>
  )
}

export default CraftManDetail;