import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import AxiosInstance from "./api/AxiosInstance";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("accessUser")) || null);
    const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log("diot",user);
    //     console.log("diot2",token);
    //   }, [user, token]);

    const signIn = async (data) => {
        try {
            const response = await AxiosInstance.post(`/signIn`, {
                data
            });
            
            if (response.data) {
                if (!response.data.success){
                    return { success: false, message: response.data.message };
                }
            
                const userWithoutPassword = { ...response.data.user };
                delete userWithoutPassword.password;

                setUser(userWithoutPassword);
                setToken(response.data.token);
                localStorage.setItem("accessUser", JSON.stringify(userWithoutPassword));
                localStorage.setItem("accessToken", response.data.token);
                navigate("/");
                return { success: true, message: "" };
            }
        } catch (err) {
            console.error(err);
        }
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("accessUser");
        localStorage.removeItem("accessToken");
        navigate("/signin");
    };

    const signUp = async (data) => {
        try {
            const response = await AxiosInstance.post(`/signUp`, {
                data
            });

            if (response.data) {
                if (!response.data.success){
                    return { success: false, message: response.data.message };
                }

                const userWithoutPassword = { ...response.data.user };
                delete userWithoutPassword.password;

                setUser(userWithoutPassword);
                setToken(response.data.token);
                localStorage.setItem("accessUser", JSON.stringify(userWithoutPassword));
                localStorage.setItem("accessToken", response.data.token);
                navigate("/");
                return { success: true, message: "" };
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <AuthContext.Provider value={{ token, user, signIn, logOut, signUp }}>
        {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};





// import React, { createContext, useContext, useState } from "react";
// import AuthService from "../api/AuthService";
// import ApiService from "../api/ApiService";

// const UserContext = createContext();

// export const useUserStore = () => {
//   return useContext(UserContext);
// };

// export const UserProvider = ({ children }) => {
//   const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
//   const [user, setUser] = useState({ role: "RESTAURANT" });

//   const login = async (email, password) => {
//     const token = await AuthService.login(email, password);
//     localStorage.setItem("accessToken", token);
//     setAccessToken(token);
//     const user = await ApiService.getLoggedUser();
//     setUser(user);
//   };

//   const register = async (email, name, password) => {
//     const token = await AuthService.register(email, name, password);
//     localStorage.setItem("accessToken", token);
//     setAccessToken(token);
//     const user = await ApiService.getLoggedUser();
//     setUser(user);
//   };

//   const loadUser = async () => {
//     if (accessToken && !user._id) {
//       const user = await ApiService.getLoggedUser();
//       setUser(user);
//     }
//   };

//   const logout = () => {
//     setAccessToken(null);
//     localStorage.removeItem("accessToken");
//   };

//   const isAdmin = user.role === "ADMIN";
//   const isRestaurant = user.role === "RESTAURANT";

//   return (
//     <UserContext.Provider value={{ accessToken, user, login, register, loadUser, logout, isAdmin, isRestaurant }}>
//       {children}
//     </UserContext.Provider>
//   );
// };
