import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CraftMenService from "../api/CraftMenService";

function CraftMenList() {
  const [craftMen, setCraftMen] = useState([]);

  async function loadCraftMen(){
    const response = await CraftMenService.getCraftMenList();

    setCraftMen(response);
  }

  useEffect(() => {
    loadCraftMen();
  }, []);

  if(!craftMen){
    return(
      <div className='text-center'>
        <h1>Aucun artisan trouvé</h1>
      </div>
    )
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-2'>
          Filtre
        </div>
        <div className='col-md-10'>
          <div className='row'>
            {craftMen.map((craftMan, index) => {
              return (
                <Link to={"/artisandetail/" + craftMan.id} className=" col-md-3 mb-3" key={'craftmen-' + index} style={{ textDecoration: 'none' }}>
                  <div className="card">
                    <img src={craftMan.image} className="card-img-top" alt="" />
                    <div className="card-body">
                      <h5 className="card-title text-center text-capitalize">{craftMan.title}</h5>
                      <p className="card-text">{craftMan.description}</p>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CraftMenList;